import styled from 'styled-components';

import { Wrapper as HeaderWrapper } from './header.styles';
import { device } from '../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;

  &.regular {
    padding-top: var(--header-height);

    ${HeaderWrapper} {
      position: absolute;
      background-color: #FFF;
    }
  }

  &.sticky {
    padding-top: var(--header-height);
  }

  @media ${device.tabletDown} {
    &.mobile-sticky {
      padding-top: var(--header-height);
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
`;
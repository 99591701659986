import { defaultLocale, locales } from './links';

const getLocaleFromUrl = (pathname = '/') => {
  let currentLocale = pathname
    .split('/')
    .filter((p) => p)
    .shift();

  if (locales.indexOf(currentLocale) === -1) {
    currentLocale = defaultLocale;
  }
  return currentLocale;
};

export default getLocaleFromUrl;

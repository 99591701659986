import React from 'react';
import { Link, navigate } from 'gatsby';
import { useLocale } from '../services/i18n/useLocale';
import { checkoutUrls, getLocaleSettings } from '../utils';

const internalDomains = [
  'ecom.ipone.breaking.agency',
  'www.ipone.com',
  'ipone.com',
];

const MagicLink = ({
  url,
  href,
  target,
  className,
  children,
  hideLink = false,
  ...props
}) => {
  const intl = useLocale();
  const settings = getLocaleSettings(intl.locale);
  let finalUrl = url || href;
  let isInternal =
    `${finalUrl}`.indexOf('http') === -1 &&
    `${finalUrl}`.indexOf('mailto') === -1;
  const rel = target ? 'noopener' : undefined;

  if (!finalUrl) {
    return (
      <span
        {...props}
        className={className}
        role="presentation"
        onClick={props.onClick}
      >
        {children}
      </span>
    );
  }

  if (!isInternal) {
    const url = new URL(finalUrl);
    isInternal = internalDomains.indexOf(url.host) !== -1;

    if (isInternal) {
      finalUrl = `${url.pathname || '/'}${url.search || ''}`;
    }
  }

  if (isInternal && finalUrl.indexOf(`/${intl.locale}`) === -1) {
    finalUrl = finalUrl
      .split('/')
      .map((part, idx) => {
        if (idx !== 1) {
          return part;
        }

        return intl.locale;
      })
      .join('/');
    // console.log(finalUrl, intl.locale, isInternal);
  }

  // Replace checkout url
  if (
    !isInternal &&
    settings.shop &&
    checkoutUrls.some((url) => finalUrl.indexOf(url) !== -1)
  ) {
    finalUrl = checkoutUrls.reduce((final, url) => {
      return final.replace(url, settings.shop.domain);
    }, finalUrl);
    const url = new URL(finalUrl);

    url.searchParams.set('locale', `${settings.cmsLocale}-${settings.locale}`);
    finalUrl = url.toString();
  }

  if (hideLink) {
    return (
      <span
        {...props}
        target={target}
        rel={rel}
        className={className}
        role="button"
        onClick={() => {
          navigate(finalUrl);
          props.onClick && props.onClick();
        }}
      >
        {children}
      </span>
    );
  }

  if (isInternal && finalUrl) {
    return (
      <Link
        {...props}
        to={finalUrl || undefined}
        target={target}
        rel={rel}
        className={className}
        onClick={props.onClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <a
      {...props}
      href={finalUrl || undefined}
      target={target || '_blank'}
      rel={rel}
      className={className}
      onClick={props.onClick}
    >
      {children}
    </a>
  );
};

export default MagicLink;

// @flow
const React = require('react');
const Url = require('url-parse');
const { default: GImage } = require('gatsby-image');
const { Helmet } = require('react-helmet');

const breakpoints = {
  xxs: 280,
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  max: 1440,
  xl: 1600,
};

// const ImgixParams = (url, params) => {
//   if (!url) {
//     return false;
//   }
//   const nUrl = new Url(url, true);

//   nUrl.query = {
//     ...nUrl.query,
//     ...params,
//   };

//   return nUrl.toString();
// }

const getImageProps = (props) => {
  const { url, sizes, noCompress, dimensions } = props;
  const respSizes = sizes || {};
  const nUrl = new Url(url, true);

  let ratio = false;
  let width = props.width;
  let height = props.height;

  // Reset height specified by Prismic
  nUrl.query.h = undefined;

  if (dimensions && dimensions.width && dimensions.height) {
    ratio = dimensions.width / dimensions.height;
  }

  // nUrl.query.dpr = dpr;

  if (noCompress && nUrl.query.auto) {
    nUrl.query.auto = nUrl.query.auto
      .replace('compress,', '')
      .replace('compress', '');
  }

  if (props.width) {
    nUrl.query.w = width;
  }

  if (props.height) {
    nUrl.query.h = height;
  }

  if (width || height) {
    nUrl.query.dpr = 2;
    if (ratio && !height) {
      height = Math.round(width / ratio);
    }

    if (ratio && !width) {
      width = Math.round(height * ratio);
    }

    if (width) {
      nUrl.query.w = width;
    }
    if (height) {
      nUrl.query.h = height;
    }

    return {
      fixed: {
        width,
        height,
        src: url ? nUrl.toString() : null,
        srcSet: url ? nUrl.toString() : null,
      },
    };
  }

  let prevRatio = 12;
  const imgSizes = Object.keys(breakpoints)
    .map((key) => {
      const ratio = respSizes[key] !== undefined ? respSizes[key] : prevRatio;
      const perc = (ratio / 12) * 100;
      const imageWidth = (perc * breakpoints[key]) / 100;
      prevRatio = ratio;
      return {
        image: imageWidth,
        key,
        perc,
        ratio,
        screen: breakpoints[key],
      };
    })
    .sort((a, b) => a.screen - b.screen);

  const srcSets = imgSizes
    .reduce((arr, s) => {
      if (arr.indexOf(s.screen) === -1) {
        arr.push(s.screen);
      }
      if (arr.indexOf(s.screen * 2) === -1) {
        arr.push(s.screen * 2);
      }
      return arr;
    }, [])
    .sort((a, b) => a - b)
    .map((w) => {
      nUrl.query.w = w;
      return `${nUrl.toString()} ${w}w`;
    })
    .join(', ');

  const srcSizes = imgSizes
    .reduce((arr, s, i) => {
      const lastItem = arr.length ? arr[arr.length - 1] : undefined;

      let value = {
        rule: '',
        width: '',
      };
      if (respSizes.contained && s.screen > breakpoints.max) {
        value.width = `${Math.round((s.perc * 1440) / 100)}px`;
      } else if (props.maxWidth && s.screen > props.maxWidth) {
        value.width = `${props.maxWidth}px`;
      } else if (i !== imgSizes.length - 1) {
        value.rule = `(max-width: ${s.screen}px)`;
        value.width = `${Math.round(s.perc * 100) / 100}vw`;
      } else {
        value.width = `${Math.round(s.perc * 100) / 100}vw`;
      }

      // if same width as previous
      if (lastItem && lastItem.width === value.width) {
        // if no rule (last item)
        if (lastItem.rule === '') {
          return arr;
        }

        // replace previous rule with current rule
        lastItem.rule = value.rule;
        return arr;
      }

      arr.push(value);
      return arr;
    }, [])
    .map((s) => `${s.rule} ${s.width}`.trim())
    .join(', ');

  return {
    fluid: {
      aspectRatio: ratio,
      src: url ? nUrl.toString() : null,
      srcSet: srcSets,
      sizes: typeof props.sizes === 'string' ? props.sizes : srcSizes,
      maxHeight: props.maxHeight,
      maxWidth: props.maxWidth,
    },
  };
};

const Image = (props) => {
  const preloadHead = props.preloadHead || false;
  let imgProps = { fluid: props.fluid, fixed: props.fixed };

  if (props.breakpoints) {
    const pixels = Object.keys(props.breakpoints);
    imgProps.fluid = pixels.map((minPx, idx) => {
      const source = props.breakpoints[minPx];
      const maxPx = pixels[idx + 1] || undefined;

      const media = getImageProps({
        ...props,
        ...source,
      });

      const constraints = [];

      if (minPx) {
        constraints.push(`min-width: ${minPx}px`);
      }
      if (maxPx) {
        constraints.push(`max-width ${maxPx - 1}px`);
      }

      return {
        ...media.fluid,
        media: `(${constraints.join(' and ')})`,
      };
    });
  } else if (!props.fluid && !props.fixed) {
    imgProps = getImageProps(props);
  }

  const classNames = ['image'];

  if (props.className) {
    classNames.push(props.className);
  }

  const newProps = { ...props };
  delete newProps.breakpoints;
  delete newProps.dimensions;
  delete newProps.sizes;
  delete newProps.style;
  delete newProps.url;
  delete newProps.maxHeight;
  delete newProps.maxWidth;
  delete newProps.preloadHead;

  if (!newProps.alt) {
    newProps.alt = 'Ipone';
  }

  // if (props.maxWidth) {
  //   console.log(imgProps);
  // }

  const finalProps = {
    ...newProps,
    ...imgProps,
    className: classNames.join(' '),
  };

  if (preloadHead && finalProps.loading === 'eager' && finalProps.fluid) {
    return (
      <>
        <Helmet>
          {(Array.isArray(finalProps.fluid)
            ? finalProps.fluid
            : [finalProps.fluid]
          ).map((source, idx) => (
            <link
              key={idx}
              rel="preload"
              as="image"
              href={source.src}
              media={source.media}
              imagesizes={source.sizes}
              imagesrcset={source.srcSet}
            />
          ))}
        </Helmet>

        <GImage {...finalProps} />
      </>
    );
  }

  return React.createElement(GImage, finalProps);
};

Image.defaultProps = {
  loading: 'lazy',
  sizes: {},
};

module.exports = Image;

import React, { useState } from 'react';
import { useLocale } from '../../services/i18n/useLocale';
import { getLocaleSettings, Imgix, splitLines } from '../../utils';
import { useWidget } from '../widgets';

import { Wrapper, Title, Text } from './Newsletter.styles';
import NewsletterForm from './NewsletterForm';

const imgResponsive = {
  xs: 12,
  lg: 8,
  contained: true,
};

const Newsletter = () => {
  const [added, setAdded] = useState(false);
  const infos = useWidget('newsletter');
  const intl = useLocale();
  const settings = getLocaleSettings(intl.locale);

  React.useEffect(() => {
    if (!added) {
      return;
    }
    const timeout = setTimeout(() => setAdded(false), 5000);

    return () => clearTimeout(timeout);
  }, [added]);

  if (!settings.mailchimpId) {
    return null;
  }

  return (
    <Wrapper>
      <div className="container">
        <div className="text">
          <Title dangerouslySetInnerHTML={{ __html: infos.title?.html }} />
          <Text
            dangerouslySetInnerHTML={{
              __html: splitLines(infos.description?.html).join('<br />'),
            }}
          />

          <NewsletterForm
          // addSamouraiTag
          />
        </div>
        <div className="cover">
          {infos?.cover?.url ? (
            <Imgix
              {...infos?.cover}
              sizes={imgResponsive}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default Newsletter;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useBreakpoint } from '../../utils';
import { useLocale } from '../../services/i18n/useLocale';

const CookieBanner = () => {
  const intl = useLocale();
  const device = useBreakpoint();

  if (device === 'ssr') {
    return null;
  }

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML: `
            window.axeptioSettings = {
              clientId: "${process.env.AXEPTIO_ID}",
              cookiesVersion: "ipone-${intl.locale}",
              userCookiesDomain: "${process.env.COOKIE_DOMAIN || 'localhost'}"
            };
            
            window.setTimeout(function () {
              (function(d, s) {
                var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                e.defer = true; e.src = "//static.axept.io/sdk-slim.js";
                t.parentNode.insertBefore(e, t);
              })(document, "script");
            }, 3000);
      `,
        },
      ]}
    />
  );
};

export default CookieBanner;

import React from 'react';
import classnames from 'classnames';

export { default as BurgerMenu } from './Burger';

const extractSVGProps = (props) => ({
  width: props.width,
  height: props.height,
  alt: props.alt,
  className: classnames('icon', props.className),
  style: props.style,
});

export const Icon = (props) => (
  <svg viewBox={props.src.viewBox} {...extractSVGProps(props)}>
    <use xlinkHref={props.src.url} />
  </svg>
);
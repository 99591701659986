const regex = /(?<=\s|\p{P}|^)(\d{1,2})W-?(\d{2})(?=\s|\p{P}|$)/gi;

export const JASOReformatViscosity = (text) => {
  if (!text) {
    return text;
  }

  const result = text.replace(regex, (match, g1, g2) => {
    return `${g1}W‑${g2}`;
  });

  return result;
};

import React, { useState } from 'react';
import cn from 'classnames';

import MagicLink from '../components/link';

import {
  Wrapper,
  MainWrapper,
  PanelWrapper,
  Panel,
  MenuEntry,
} from './MobileMenu.styles';
import { useLocale } from '../services/i18n/useLocale';

import trads from './trads';

const Items = ({
  items,
  onHide,
}) => {
  return (
    <Panel>
      {items.map((item, subidx) => (
        <div key={subidx} className="section">
          {item.title && (
            <MagicLink
              {...item.link}
              className="heading anim-item anim-item-1"
              onClick={onHide ? onHide : undefined}
            >
              {item.title}
            </MagicLink>
          )}
          <div className="subitems">
            <ul>
              {item.items.map((subitem, idx) => (
                <li
                  key={idx}
                  className={`anim-item anim-item-${idx + 2}`}
                >
                  <MagicLink
                    {...subitem.link}
                    onClick={onHide ? onHide : undefined}
                  >
                    {subitem.title}
                  </MagicLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </Panel>
  );
};

const MobileMenu = ({
  menu,
  show,
  onHide,
  className,
  children,
}) => {
  const intl = useLocale();
  const [currentSection, setCurrentSection] = useState();
  return (
    <Wrapper className="menu-mobile">
      <MainWrapper
        className={cn(className, {
          hide: !!currentSection,
        })}
      >
        {menu.map((section, idx) => (
          <MenuEntry
            key={idx}
            onClick={() => {
              setCurrentSection(section);
            }}
          >
            {section.title}
          </MenuEntry>
        ))}
        {children}
      </MainWrapper>
      <PanelWrapper
        className={cn({
          open: !!currentSection,
        })}
      >
        <button
          className="clear back-button"
          onClick={() => setCurrentSection(undefined)}
        >
          <span>&lt;</span> {intl.formatMessage(trads.headerMenuBack)}
        </button>
        <div className="menu-title">{currentSection?.title}</div>
        <Items
          items={(currentSection?.sections || [])}
          onHide={onHide}
        />
      </PanelWrapper>
    </Wrapper>
  );
};

export default MobileMenu;

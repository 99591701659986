import {
  useContext
} from 'react';
import provider, {
  StoreContext
} from './provider';

export const ShopifyProvider = provider;

const useShopify = () => useContext(StoreContext);
export default useShopify;
import React from 'react';

const regVars = /\{(.*?)\}/g;
const regMarkup = /<\s*([^>]+)>(.*?)<\s*\/[^>]*>|<br\s*\/>(.*?)/gi;

const parseMarkup = (inputStr, params = {}) => {
  const chunks = [...inputStr.matchAll(regMarkup)].map((parts) => {
    const cb = params[parts[1]];
    let partContent = parts[2];

    if (cb && typeof cb !== 'function') {
      partContent = cb;
    } else if (cb) {
      partContent = cb(parts[2]);
    }

    return {
      split: parts[0],
      content: partContent,
      startIndex: parts.index,
      endIndex: parts.index + parts[0].length,
    };
  });

  if (!chunks.length) {
    return inputStr;
  }

  const strArr = chunks
    .reduce((arr, part, idx) => {
      const lastChunk = idx > 0 ? chunks[idx - 1] : undefined;

      // insert previous text
      arr.push(inputStr.slice(lastChunk?.endIndex || 0, part.startIndex));

      if (!part.content && part.split.indexOf('<br') !== -1) {
        part.content = <br />;
      }

      if (part.split.indexOf('<strong') === 0) {
        part.content = <strong>{part.content}</strong>;
      }

      // insert current content
      arr.push(part.content);

      if (idx === chunks.length - 1) {
        arr.push(inputStr.slice(part.endIndex));
      }

      return arr;
    }, [])
    .map((part, idx) => <React.Fragment key={idx}>{part}</React.Fragment>);


  //   console.log(strArr, chunks);
  return strArr;
};

export const formatMessage = (translations = {}, key, params = {}) => {
  const text = translations[key] || '';
  let newText = text;

  // replace basique variables
  newText = newText
    .split(regVars)
    .map((item) => {
      if (params[item] === undefined) {
        return item;
      }

      return params[item] || '';
    })
    .join('');

  //replace markup
  newText = parseMarkup(newText, params);

  return newText;
};

import React from 'react';
import MagicLink from '../components/link';
import Logo from '../components/Logo';

import { useWidget } from '../components/widgets';
import { Imgix } from '../utils';

import { Icon } from '../components/icons/';
import Facebook from '../icons/facebook.svg';
import Youtube from '../icons/youtube.svg';
import Instagram from '../icons/instagram.svg';
import TikTok from '../icons/TikTok.svg';

import trads from './trads';

import { Wrapper } from './footer.styles';
import { getLegalLink, getCreditsLink } from '../utils/links';
import { useLocale } from '../services/i18n/useLocale';

const MenuItem = ({ entry }) => {
  return (
    <li>
      <MagicLink className="white menu-link" {...entry.link}>
        {entry.title}
      </MagicLink>
      <ul className="subitems">
        {entry.items.map((subitem, idx) => (
          <li key={idx}>
            <MagicLink {...subitem.link}>{subitem.title}</MagicLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

const MenuItems = ({ menu = [] }) => {
  return menu.map((entry, idx) => <MenuItem key={idx} entry={entry} />);
};

const imgResponsive = {
  xxs: 0,
  sm: 6,
  contained: true,
};

const Footer = ({ menu }) => {
  const intl = useLocale();
  const widget = useWidget('footer');

  return (
    <Wrapper>
      <div className="background">
        {widget.baseline?.url && (
          <Imgix
            alt="Passion first"
            {...widget.baseline}
            loading="lazy"
            // maxWidth={800}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'bottom right',
            }}
            sizes={imgResponsive}
          />
        )}
      </div>
      <div className="container">
        <div className="top">
          <ul className="menu">
            <MenuItems menu={menu} />
          </ul>
          <div className="social">
            <a
              href="https://facebook.com/iponelube/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <Icon src={Facebook} height="21px" />
            </a>
            <a
              href="https://www.youtube.com/user/iponelube"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="youtube"
            >
              <Icon src={Youtube} height="21px" />
            </a>
            <a
              href="https://instagram.com/iponelube"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <Icon src={Instagram} height="21px" />
            </a>
            <a
              href="https://vm.tiktok.com/ZMLJA5FVQ/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <Icon src={TikTok} height="21px" />
            </a>
          </div>
        </div>
        <div className="bottom">
          <div className="logo">
            <MagicLink href="/" aria-label="Ipone">
              <Logo className="white" />
            </MagicLink>
            <div className="legal">
              <MagicLink href={getLegalLink(intl.locale)} rel="noopener">
                {intl.formatMessage(trads.footerCGV)}
              </MagicLink>
              &nbsp;|&nbsp;
              <MagicLink href={getCreditsLink(intl.locale)}>
                {intl.formatMessage(trads.footerCredits)}
              </MagicLink>
              &nbsp;|&nbsp;
              <a
                href="#cookies"
                onClick={(e) => {
                  e.preventDefault();
                  window?.openAxeptioCookies();
                }}
              >
                Cookies
              </a>
              &nbsp;|&nbsp; Contact&nbsp;:&nbsp;
              <a href="mailto:info@ipone.fr">info@ipone.fr</a>
              <br />® IPONE SA {new Date().getFullYear()} All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;

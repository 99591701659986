import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import classnames from 'classnames';

import Logo from '../components/Logo';
import { BurgerMenu, Icon } from '../components/icons';

import PoiIcon from '../icons/poi.svg';
import DarkPOIIcon from '../icons/DarkPOI.svg';
import HelmetIcon from '../icons/helmet.svg';
import DarkHelmetIcon from '../icons/DarkHelmet.svg';
import SearchIcon from '../icons/Search.svg';

import MagicLink from '../components/link';
import CartMenu from '../components/cart/CartMenu';
import Submenu from './Submenu';
import MobileMenu from './MobileMenu';

import { getUrl, getLocaleSettings, useBreakpoint, isClient } from '../utils';

import trads from './trads';

import { Wrapper } from './header.styles';
import LocaleSelector from './LocaleSelector';
import LocaleSelectorInline from './LocaleSelectorInline';
import { useLocale } from '../services/i18n/useLocale';

const MenuItem = ({ entry, isMobile }) => {
  const [hover, setHover] = useState(false);
  const onEnter = useCallback(() => setHover(true), [setHover]);
  const onLeave = useCallback(() => setHover(false), [setHover]);
  const toggle = useCallback(() => setHover((prev) => !prev), [setHover]);
  const subItemsLength = entry.sections?.length;

  const onItemClick = useCallback(
    (e) => {
      if (!isMobile || !subItemsLength) {
        onLeave();
        return;
      }
      e.preventDefault();
      toggle();
    },
    [isMobile, subItemsLength, onLeave, toggle]
  );

  return (
    <li
      onMouseEnter={!isMobile ? onEnter : undefined}
      onMouseLeave={!isMobile ? onLeave : undefined}
      role="presentation"
    >
      <MagicLink
        className={classnames('entry-link  black', {
          hover,
        })}
        {...entry.link}
        hideLink={!!entry.seo_hide}
        onClick={onItemClick}
      >
        {entry.title}
      </MagicLink>
      {subItemsLength ? (
        <Submenu
          key={entry.title}
          items={entry.sections}
          show={hover}
          onHide={onLeave}
          header
          isMobile={isMobile}
        />
      ) : null}
    </li>
  );
};

const MenuItems = ({ menu = [], mobileMode }) => {
  const device = useBreakpoint();
  const isMobile = device === 'mobile' || device === 'tablet';

  if (mobileMode && !isMobile) {
    return null;
  }

  if (!mobileMode && isMobile) {
    return null;
  }

  return menu.map((entry, idx) => (
    <MenuItem key={idx} entry={entry} isMobile={isMobile} />
  ));
};

const checkIsFloating = (y) => y > 90;
const useIsFloating = () => {
  const [isFloating, setIsFloating] = useState(
    checkIsFloating(isClient ? window.pageYOffset : 0)
  );

  useEffect(() => {
    const handler = () => {
      const newState = checkIsFloating(window.pageYOffset);
      if (newState === isFloating) {
        return;
      }
      setIsFloating(newState);
    };
    window.addEventListener('scroll', handler, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [isFloating]);

  return isFloating;
};

const Header = ({
  displayShadow = true,
  alternateLocales = [],
  menu = [],
  shopOptions,
}) => {
  const intl = useLocale();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isFloating = useIsFloating();

  const settings = getLocaleSettings(intl.locale);

  // close menu if location change
  useEffect(() => {
    return () => {
      isMenuOpen && setIsMenuOpen(false);
    };
  }, [location, isMenuOpen]);

  return (
    <Wrapper
      className={classnames({
        'is-floating': isFloating,
        'is-menu-open': isMenuOpen,
        'hide-shadow': !displayShadow,
      })}
    >
      <div className="container">
        <div className="logo">
          <Link to={getUrl('/', intl.locale)} aria-label="Ipone">
            <span>Ipone</span>
            <Logo
              className={classnames({
                white: isMenuOpen,
              })}
            />
          </Link>
        </div>
        <ul className="menu h-tablet h-mobile">
          <MenuItems menu={menu} />
        </ul>
        <ul className="right">
          {shopOptions?.links?.search?.url && (
            <li className="menu-item-link show-mobile search-icon">
              <MagicLink
                href={shopOptions.links?.search?.url}
                aria-label="Search"
              >
                <Icon
                  src={SearchIcon}
                  width={16}
                  height={16}
                  style={{ margin: 0 }}
                />
              </MagicLink>
            </li>
          )}
          <li className="menu-item-link h-mobile locale">
            <LocaleSelector alternateLocales={alternateLocales} />
          </li>
          <MagicLink
            href={shopOptions.links?.storelocator?.url}
            className="menu-item-link menu-item-desktop"
          >
            <span>{intl.formatMessage(trads.headerStoreLocator)}</span>
            <Icon
              src={PoiIcon}
              width={20}
              height={20}
              className="account-icon"
            />
          </MagicLink>
          {settings.accountUrl && (
            <a
              href={`https://${settings.shop.domain}${settings.accountUrl}`}
              className="menu-item-link menu-item-desktop"
            >
              <span>{intl.formatMessage(trads.headerAccount)}</span>
              <Icon
                src={HelmetIcon}
                width={22}
                height={20}
                className="account-icon"
              />
            </a>
          )}
          {settings.shop && (
            <li className="menu-item-desktop show-mobile">
              <CartMenu options={shopOptions.cart} />
            </li>
          )}

          <li>
            <BurgerMenu
              direction="right"
              className={classnames('menu-item-burger', {
                open: isMenuOpen,
              })}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
          </li>
        </ul>
      </div>
      <MobileMenu menu={menu}>
        <ul className="bottom-entries">
          <li>
            <MagicLink
              href={shopOptions.links?.storelocator?.url}
              className="menu-item-link"
            >
              <Icon
                src={DarkPOIIcon}
                width={22}
                height={20}
                className="account-icon mobile"
              />
              <span>{intl.formatMessage(trads.headerStoreLocator)}</span>
            </MagicLink>
          </li>
          {settings.shop && (
            <li>
              <a
                href={`https://${settings.shop.domain}/account`}
                className="menu-item-link"
              >
                <Icon
                  src={DarkHelmetIcon}
                  width={22}
                  height={20}
                  className="account-icon mobile"
                />
                <span>{intl.formatMessage(trads.headerAccount)}</span>
              </a>
            </li>
          )}
          <li>
            <LocaleSelectorInline alternateLocales={alternateLocales} />
          </li>
        </ul>
      </MobileMenu>
    </Wrapper>
  );
};

export default Header;

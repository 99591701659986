import Product from './Product';

const transformCategory = (raw) => {
  const category = { ...raw };
  category.products = (category.products || []).map(p => Product(p));

  return category;
}

export default transformCategory;

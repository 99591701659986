// @flow
import React from 'react';
import classnames from 'classnames';

import { Wrapper } from './Burger.styles';

const Burger = ({
  className,
  isOpen,
  direction = null,
  ...rest
}) => (
  <Wrapper
    className={ classnames('burger', className, {
      open: isOpen,
      [`burger-${direction}`]: direction,
    }) }
    { ...rest }
    aria-label="Menu"
  >
    <div className="burger-lines" />
  </Wrapper>
)

Burger.defaultProps = {
  className: null,
  isOpen: false,
  direction: null,
}


export const createBurger = defaultClassName => ({ className, direction, ...rest }) => (
  <Burger
    className={ classnames(defaultClassName, className, {
      [`burger-${direction}`]: direction,
    }) }
    { ...rest }
  />
)
export default Burger
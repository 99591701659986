import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import client from './client';
// import queries from './queries';

const isBrowser = typeof window !== 'undefined';
const savedToken = isBrowser
  ? JSON.parse(localStorage.getItem('shopify_token'))
  : null;
const existingCheckoutID = isBrowser
  ? localStorage.getItem('shopify_checkout_id')
  : null;

const initialContext = {
  adding: false,
  removing: false,
  updating: false,
  checkout: {
    lineItems: [],
  },
  auth: {
    logged: !!savedToken,
    accessToken: undefined,
    expiresAt: undefined,
    ...savedToken,
  },
  user: undefined,
};

let ensuringCheckoutPromise;

// let client = null;

const getCheckoutOrders = () => {
  try {
    return JSON.parse(localStorage.getItem('shopify_checkout_items') || []);
  } catch (e) {
    return [];
  }
};

const resetCheckoutOrders = () => {
  localStorage.removeItem('shopify_checkout_items');
};

const appendCheckoutOrders = (variantIds) => {
  const orders = getCheckoutOrders();
  const newOrders = [...orders, ...variantIds];
  localStorage.setItem('shopify_checkout_items', JSON.stringify(newOrders));
};

const removeCheckoutOrders = (variantId) => {
  const orders = getCheckoutOrders().filter((v) => v !== variantId);
  localStorage.setItem('shopify_checkout_items', JSON.stringify(orders));
  return orders;
};

const initClient = async (domain, token, locale) => {
  const { default: Shopify } = await import(
    /* webpackChunkName: "shopify-buy" */ 'shopify-buy'
  );

  console.log('init shopify client');
  return Shopify.buildClient({
    storefrontAccessToken: token,
    domain,
    language: locale,
    apiVersion: '2024-04',
  });
  // console.log('[Shopify] Init - Client ready');

  // return client;
};

const initCheckout = async (client) => {
  let newCheckout;

  if (existingCheckoutID) {
    try {
      newCheckout = await client.checkout.fetch(existingCheckoutID);
      // Make sure this cart hasn’t already been purchased.
      if (newCheckout.completedAt) {
        newCheckout = null;
      }
    } catch (e) {
      localStorage.setItem('shopify_checkout_id', null);
    }
  }

  if (!newCheckout) {
    newCheckout = await client.checkout.create();
    localStorage.setItem('shopify_checkout_id', newCheckout.id);
    resetCheckoutOrders();
  }
  // console.log('[Shopify] Init - Checkout ready', newCheckout);
  return newCheckout;
};

const ensureCheckout = async (client, checkout) => {
  if (!client) {
    return;
  }

  if (checkout?.id) {
    return checkout;
  }

  if (ensuringCheckoutPromise) {
    // console.log('Already initializing, waiting for promise');
    return ensuringCheckoutPromise;
  }
  ensuringCheckoutPromise = initCheckout(client);

  ensuringCheckoutPromise.then(() => {
    ensuringCheckoutPromise = null;
  });
  return ensuringCheckoutPromise;
};

const addToCart = async (
  ensureClient,
  inCheckout,
  update,
  variantId,
  quantity
) => {
  const client = await ensureClient();
  const checkout = await ensureCheckout(client, inCheckout);
  const variantIds = Array.isArray(variantId)
    ? variantId
    : [variantId].filter((id) => id);

  if (!variantIds.length || !quantity) {
    console.error('Both a size and quantity are required.');
    return;
  }

  update((prevState) => ({
    ...prevState,
    adding: true,
  }));

  const lineItemsToUpdate = variantIds.map((id) => ({
    variantId: id,
    quantity: parseInt(quantity, 10),
  }));

  appendCheckoutOrders(variantIds);

  const newCheckout = await client.checkout.addLineItems(
    checkout.id,
    lineItemsToUpdate
  );

  update((prevState) => ({
    ...prevState,
    adding: false,
    checkout: newCheckout,
  }));
};

const fetchProduct = async (ensureClient, productId) => {
  const client = await ensureClient();
  return client.product.fetch(productId);
};
const removeLineItem = async (ensureClient, inCheckout, update, line) => {
  const client = await ensureClient();
  const checkout = await ensureCheckout(client, inCheckout);

  update((prevState) => ({
    ...prevState,
    removing: true,
  }));

  if (line?.variant?.id) {
    removeCheckoutOrders(line?.variant?.id);
  }

  const newCheckout = await client.checkout.removeLineItems(checkout.id, [
    line?.id,
  ]);

  update((prev) => ({
    ...prev,
    removing: false,
    checkout: newCheckout,
  }));
};

const updateLineItem = async (
  ensureClient,
  inCheckout,
  update,
  lineItemID,
  quantity
) => {
  const client = await ensureClient();
  const checkout = await ensureCheckout(client, inCheckout);

  update((prevState) => ({
    ...prevState,
    updating: true,
  }));

  const newCheckout = await client.checkout.updateLineItems(checkout.id, [
    { id: lineItemID, quantity },
  ]);

  update((prev) => ({
    ...prev,
    updating: false,
    checkout: newCheckout,
  }));
};

export const StoreContext = React.createContext(initialContext);

const ContextProvider = ({ domain, token, locale, children }) => {
  const [store, updateStore] = useState(initialContext);

  const ensureClient = useMemo(() => {
    let client = null;
    let ensuringClientPromise = null;
    return () => {
      if (!domain || !token) {
        return null;
      }

      if (client) {
        return client;
      }

      if (ensuringClientPromise) {
        // console.log('Already initializing, waiting for promise');
        return ensuringClientPromise;
      }
      ensuringClientPromise = initClient(domain, token, locale);

      ensuringClientPromise.then((newClient) => {
        client = newClient;
        ensuringClientPromise = null;
      });

      return ensuringClientPromise;
    };
  }, [domain, token, locale]);

  useEffect(() => {
    const initializeCheckout = async () => {
      if (!isBrowser || !existingCheckoutID) {
        return;
      }

      // console.log('[Shopify] Has existing checkout, init early');
      const client = await ensureClient();
      const checkout = await ensureCheckout(client);
      updateStore((prev) => ({
        ...prev,
        checkout,
      }));
    };

    initializeCheckout();
  }, [ensureClient]);

  const cartOrders = getCheckoutOrders();

  const state = {
    ...store,
    loading: store.adding || store.removing || store.updating,
    // ready: !!client,
    ready: false,
    addToCart: useCallback(
      (...props) => {
        return addToCart(ensureClient, store.checkout, updateStore, ...props);
      },
      [updateStore, store.checkout, ensureClient]
    ),
    removeLineItem: useCallback(
      (...props) => {
        return removeLineItem(
          ensureClient,
          store.checkout,
          updateStore,
          ...props
        );
      },
      [updateStore, store.checkout, ensureClient]
    ),
    updateLineItem: useCallback(
      (...props) => {
        return updateLineItem(
          ensureClient,
          store.checkout,
          updateStore,
          ...props
        );
      },
      [updateStore, store.checkout, ensureClient]
    ),
    fetchProduct: useCallback(
      (...props) => {
        return fetchProduct(ensureClient, ...props);
      },
      [ensureClient]
    ),
    cartItems: store.checkout?.lineItems?.sort((a, b) => {
      const aPos = cartOrders.indexOf(a?.variant?.id);
      const bPos = cartOrders.indexOf(b?.variant?.id);
      return aPos - bPos;
    }),
    lastAddedItem: store.checkout?.lineItems?.find((item) => {
      // try {
      //   const lastItemId = atob(cartOrders?.slice()?.pop());
      //   return item?.variant?.id === lastItemId;
      // } catch (e) {
      //   console.error(e);
      // }
      const lastItemId = cartOrders?.slice()?.pop();

      return item?.variant?.id === lastItemId;

    })
  };

  // console.log('[Shopify] Hook update', state);

  return (
    <StoreContext.Provider value={state}>{children}</StoreContext.Provider>
  );
};
export default ContextProvider;

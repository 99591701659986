import React from 'react';
import styled from 'styled-components';

import { Icon } from '../components/icons';
import GlobeIcon from '../icons/Globe.svg';
import ChevronIcon from '../icons/Chevron.svg';
import { useLocale } from '../services/i18n/useLocale';
import { locales } from '../utils';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;

  .chevron {
    transform: rotate(90deg);
    filter: brightness(0) invert(1);
    margin-left: 5px;
  }

  .globe {
    margin-right: 0;
  }
`;

const List = styled.div`
  position: absolute;
  top: 100%;
  left: -10px;
  right: 20px;
  background-color: #000;
  text-align: left;

  a {
    display: block;
    padding: 7px 10px;
    border-bottom: 1px solid #fff;

    &:first-child {
      padding-top: 15px;
    }
  }
`;

const LocaleSelector = ({ alternateLocales = [] }) => {
  const intl = useLocale();
  const [hover, setHover] = React.useState(false);
  const setIsHover = React.useCallback(() => setHover(true), []);
  const setNotHover = React.useCallback(() => setHover(false), []);

  return (
    <Wrapper onMouseEnter={setIsHover} onMouseLeave={setNotHover}>
      <span>{intl.locale}</span>
      <Icon src={ChevronIcon} width={10} height={10} className="chevron" />
      <Icon src={GlobeIcon} width={16} height={16} className="globe" />
      {hover && (
        <List>
          {alternateLocales
            .filter(alt => locales.indexOf(alt.locale) !== -1)
            .map((alt) => (
              <a key={alt.locale} href={alt.url}>
                {alt.locale}
              </a>
            ))}
        </List>
      )}
    </Wrapper>
  );
};

export default LocaleSelector;

import styled from 'styled-components';

export const Wrapper = styled.form`
  input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 10px 20px;
    border: solid 1px #000000;
    background-color: #ffffff;
    -webkit-appearance: none;
    outline: none;
    margin: 20px 0;

    transition: border-color 0.3s;

    &:focus {
      border-color: #949494;
    }

    &.added {
      border-color: #808080;
      ::placeholder {
        color: #000;
        font-weight: 500;
      }
    }
  }

  button {
    color: #fff;
  }
`;

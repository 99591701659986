import styled from 'styled-components';
// import { device } from '../utils';

export const Wrapper = styled.div`
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  background: #fff;
  transition: height 300ms ease-in;
  overflow-y: auto;
  color: #111111;
`;

export const MainWrapper = styled.div`
  margin: 20px;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &.hide {
    transform: translateX(-20%);
  }

  & > ul {
    list-style: none;
    padding: 0;

    & > li {
      margin: 20px 0;
    }
  }

  .menu-item-link {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    padding: 5px 10px 5px 0px;
  }

  .bottom-entries {
    &,
    a span {
      color: #111111;
    }

    svg {
      width: 20px;
      margin-left: 0 !important;
      margin-right: 15px;
    }
  }
`;

export const PanelWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 100;
  transform: translateX(100%);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &.open {
    transform: translateX(0);
  }

  padding: 20px;

  .back-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px;
    padding-left: 0;
    color: #000;

    span {
      display: inline-block;
      transform: scale(0.6, 1.1);
      margin-right: 5px;
    }
  }
  .menu-title {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;

export const MenuEntry = styled.div`
  font-family: 'Montserrat';
  position: relative;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  padding: 10px 0;
  margin: 5px 0;

  &:after {
    content: '>';
    position: absolute;
    top: 0%;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    transform: scale(0.6, 1.1);
  }
`;

export const Panel = styled.div`
  padding-bottom: 30px;

  .section {
    .heading {
      font-family: 'Montserrat';
      display: block;
      font-weight: bold;
      font-size: 15px;
    }

    a {
      position: relative;

      &:after {
        content: '>';
        position: absolute;
        top: 0%;
        bottom: 0;
        right: 0;
        font-size: 12px;
        color: #757575;
        display: flex;
        align-items: center;
        transform: scale(0.6, 1.1);
      }
    }

    ul {
      margin-top: 10px;
      margin-bottom: 20px;
      padding-left: 0;
      list-style: none;

      li {
        margin: 5px 0;

        a,
        span {
          display: block;
          color: #757575;
          padding: 5px 0;
          font-size: 14px;
        }
      }
    }
  }
`;

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// https://markoskon.com/extremely-fast-load-time-with-gatsby-and-self-hosted-fonts/
import React from 'react';
import { render, hydrate } from 'react-dom';

import '@fontsource/montserrat/latin-400.css';
import '@fontsource/montserrat/latin-700.css';
import '@fontsource/montserrat/latin-900.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';
import '@fontsource/roboto/latin-900.css';

import Layout from './src/layout';
import { resetDataLayer } from './src/utils/gtm';
import getLocaleFromUrl from './src/utils/getLocaleFromUrl';
import { getLocaleSettings } from './src/utils/links';

// export const wrapPageElement = Layout;

export const wrapPageElement = ({ element, props }) => {
  // Reset gtm ecommerce tracking between screens.
  // If we don't, the ecommerce attr will be passed in all following events if not overrided
  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: undefined,
      productName: undefined,
    });
  }

  return (
    <Layout localeData={window.iponeLocale} {...props}>
      {element}
    </Layout>
  );
};

export const replaceHydrateFunction = () => {
  const currentLocale = getLocaleFromUrl(window.location.pathname);
  const settings = getLocaleSettings(currentLocale);
  const fetchLocale = fetch(
    `/locales/${settings.cmsLocale}.json?v=${window.iponeLocaleHash}`
  );

  const renderFn = process.env.BUILD_STAGE.includes('develop')
    ? render
    : hydrate;

  let localeJson;

  return (element, container, callback) => {
    if (localeJson) {
      window.iponeLocale = localeJson;
      return renderFn(element, container, callback);
    }

    fetchLocale
      .then(async (res) => {
        window.iponeLocale = localeJson = await res.json();
      })
      .catch((e) => {
        console.error('Locale fetch error', e);
      })
      .finally(() => {
        renderFn(element, container, callback);
      });
  };
};

// const sendToGTM = ({name, delta, id}) => {
//   // Assumes the global `dataLayer` array exists, see:
//   // https://developers.google.com/tag-manager/devguide
//   if (process.env.NODE_ENV !== 'production') {
//     // console.info('Perf', name, delta, id);
//     return;
//   }

//   window.dataLayer.push({
//     event: 'web-vitals',
//     event_category: 'Web Vitals',
//     event_action: name,
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     event_value: Math.round(name === 'CLS' ? delta * 1000 : delta),
//     // The `id` value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     event_label: id,
//   });
// }

export const onPreRouteUpdate = () => {
  resetDataLayer();
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    const data = window.dataLayer;
    const eventName = 'pageview';

    data.push({ event: eventName });
  }, 50);
};

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  // getCLS(sendToGTM);
  // getFID(sendToGTM);
  // getLCP(sendToGTM);
};

export const onInitialClientRender = (props) => {
  // wait to init GTM until after React has hydrated in this lifecycle
  // Load GTM
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.defer = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-NC7539G');

  const currentLocale = getLocaleFromUrl(window.location.pathname);

  window.dataLayer.push({
    siteVersion: process.env.SITE_VERSION,
    environment: process.env.ENVIRONMENT || 'dev',
    country: currentLocale,
  });
};

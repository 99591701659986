const getLocale = (lang) => `${lang}`.toLowerCase().split('-').shift();
const defaultLocale = 'fr';
const locales = ['fr', 'en', 'es', 'it', 'de', 'be'];

const shops = {
  fr: {
    locales: ['fr'],
    domain: 'checkout.ipone.com',
    token: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  },
  eu: {
    locales: ['en', 'es', 'be', 'it', 'de'],
    domain: 'checkout.eu.ipone.com',
    token: process.env.GATSBY_EU_SHOPIFY_ACCESS_TOKEN,
  },
};

const checkoutUrls = Object.keys(shops).map((locale) => {
  return shops[locale].domain;
});

const localeSettings = {
  fr: {
    locale: 'fr',
    fullLocale: 'fr',
    cmsLocale: 'fr',
    cmsLocaleGlob: 'fr-*',
    shop: shops.fr,
    mailchimpId: '0d5087c66c',
    accountUrl: '/account'
  },
  en: {
    locale: 'en',
    fullLocale: 'en',
    cmsLocale: 'en',
    cmsLocaleGlob: 'en-*',
    shop: false,
    mailchimpId: '0d5087c66c',
  },
  es: {
    locale: 'es',
    fullLocale: 'es',
    cmsLocale: 'es',
    cmsLocaleGlob: 'es-*',
    shop: shops.eu,
    mailchimpId: 'f2f3963d04',
    accountUrl: '/es-es/account'
  },
  be: {
    locale: 'be',
    fullLocale: 'fr-BE',
    cmsLocale: 'fr',
    cmsLocaleGlob: 'fr-*',
    shop: shops.eu,
    mailchimpId: 'f2f3963d04',
    accountUrl: '/account'
  },
  it: {
    locale: 'it',
    fullLocale: 'it',
    cmsLocale: 'it',
    cmsLocaleGlob: 'it-*',
    shop: shops.eu,
    mailchimpId: 'f2f3963d04',
    accountUrl: '/it-it/account'
  },
  de: {
    locale: 'de',
    fullLocale: 'de',
    cmsLocale: 'de',
    cmsLocaleGlob: 'de-*',
    shop: shops.eu,
    mailchimpId: 'f2f3963d04',
    accountUrl: '/de-de/account'
  },
};

const duplicatedLocales = Object.keys(localeSettings).reduce((res, locale) => {
  const current = localeSettings[locale];

  if (current.locale === current.cmsLocale) {
    return res;
  }

  if (!res[current.cmsLocale]) {
    res[current.cmsLocale] = [];
  }

  res[current.cmsLocale].push(locale);
  return res;
}, {});

const getLocaleSettings = (locale = defaultLocale, noFallback = false) => {
  if (localeSettings[locale]) {
    return localeSettings[locale];
  }

  if (noFallback) {
    return false;
  }

  return localeSettings[defaultLocale];
};

const getPrismicAlternates = (locale, node) => {
  const settings = getLocaleSettings(locale);
  const alternates = (node.languages || []).reduce((res, alt) => {
    const localDups = duplicatedLocales[getLocale(alt.lang)] || [];
    if (!localDups.length) {
      return res;
    }

    localDups.forEach((l) =>
      res.push({
        ...alt,
        lang: l,
      })
    );
    return res;
  }, (node.languages || []).slice());

  const localDups = duplicatedLocales[settings.locale] || [];

  if (localDups.length) {
    localDups.forEach((l) => {
      alternates.push({
        ...node,
        lang: l,
      });
    });
  }

  if (settings.locale !== settings.cmsLocale) {
    alternates.push({
      ...node,
      lang: settings.cmsLocale,
    });
  }

  return alternates;
};

const getEshopUrl = (locale = defaultLocale) => {
  const lang = getLocale(locale);
  return `/${lang}/eshop/`;
};

const getProductUrl = (handle, locale = defaultLocale, variant) => {
  const lang = getLocale(locale);
  let section;
  const variantHandle = (
    variant && variant.viscosity ? `-${variant.viscosity?.replace(/w-/gi, 'w')}` : ''
  )
    .toLowerCase()
    .replace(' ', '-');

  const settings = getLocaleSettings(locale);
  switch (settings.cmsLocale) {
    case 'fr':
      section = 'produit';
      break;
    default:
      section = 'product';
  }

  let cleanHandle = `${handle || ''}`.trim();

  if (cleanHandle.endsWith('_1')) {
    cleanHandle = cleanHandle.slice(0, -2);
  }

  return `/${lang}/eshop/${section}/${cleanHandle}${variantHandle}`;
};

const getCategoryUrl = (handle = '', locale = defaultLocale) => {
  if (handle === '') {
    return getEshopUrl(locale);
  }
  const lang = getLocale(locale);
  const settings = getLocaleSettings(lang);
  let part;
  switch (settings.cmsLocale) {
    case 'fr':
      part = 'categorie';
      break;
    case 'es':
    case 'it':
      part = 'categoria';
      break;
    default:
      part = 'category';
  }

  return `/${lang}/eshop/${part}/${handle}`;
};

const getStoriesUrl = (handle = '', locale = defaultLocale) => {
  if (!handle) {
    return `/${locale}/stories`;
  }
  return `/${locale}/stories/${handle}`;
};

const getProductPremiumUrl = (handle, locale = defaultLocale) => {
  const lang = getLocale(locale);
  return `/${lang}/eshop/premium/${handle}`;
};

const getConfiguratorUrl = (handle, locale = defaultLocale) => {
  const configuratorPages = {
    fr: 'trouver-la-bonne-huile-moto',
    it: 'trova-il-miglior-olio-per-la-tua-moto',
    en: 'find-the-right-motorcycle-oil',
    es: 'encuentra-el-mejor-aceite-para-tu-moto',
    de: 'finde-das-beste-ol'
  };

  let url = `/${locale}/${configuratorPages[locale] || configuratorPages.fr}`;

  if (handle) {
    url = `${url}/${handle}`;
  }

  return url;
};


const getArticleUrl = (handle, locale = defaultLocale) => {
  const lang = getLocale(locale);
  let section;
  switch (locale) {
    case 'es':
      section = 'articulos';
      break;
    default:
      section = 'articles';
  }

  let url = `/${lang}/${section}`;

  if (handle) {
    url = `${url}/${handle}`;
  }

  return url;
};

const getLegalLink = (locale = defaultLocale) => {
  if (locale === 'en') {
    return getUrl('general-conditions-of-sale', locale);
  }

  if (locale === 'es') {
    return getUrl('condiciones-generales-de-venta', locale);
  }

  if (locale === 'it') {
    return getUrl('condizioni-generali-vendita', locale);
  }

  if (locale === 'de') {
    return getUrl('allgemeine-geschaftsbedingungen', locale);
  }

  return getUrl('conditions-generales-de-vente', locale);
};

const getCreditsLink = (locale = defaultLocale) => {
  if (locale === 'en') {
    return getUrl('credits', locale);
  }

  if (locale === 'es') {
    return getUrl('creditos', locale);
  }

  if (locale === 'de') {
    return getUrl('impressum', locale);
  }

  return getUrl('credits', locale);
};

const getUrl = (handle, locale = defaultLocale) => {
  const lang = getLocale(locale);
  let path = handle || '';
  if (path.indexOf('/') !== 0) {
    path = `/${path}`;
  }

  if (path === '/' && locale === defaultLocale) {
    return '/';
  }

  if (path === '/') {
    return `/${lang}`;
  }

  return `/${lang}${path}`;
};

const resolvePrismicLink = (doc) => {
  const locale = getLocale(doc.lang || defaultLocale);

  if (
    doc.type === 'homepage' ||
    doc.type === 'widgets' ||
    doc.type === 'menu'
  ) {
    return locale === defaultLocale ? '/' : `/${getLocale(doc.lang)}`;
  }

  if (doc.type === 'homearticle') {
    return getArticleUrl('', locale);
  }

  if (doc.type === 'eshophome') {
    return getEshopUrl(locale);
  }

  if (doc.type === 'stories') {
    return getStoriesUrl(doc.uid, locale);
  }

  if (doc.type === 'stories_home') {
    return getStoriesUrl('', locale);
  }

  if (doc.type === 'premium') {
    return getProductPremiumUrl(doc.uid, locale);
  }

  if (
    doc.type === 'page' ||
    doc.type === 'partner-page' ||
    doc.type === 'productrange_categories' ||
    doc.type === 'tuto' ||
    doc.type === 'home_tuto' ||
    doc.type === 'landing_page' ||
    doc.type === 'home_tips' ||
    doc.type === 'home_gammes'
  ) {
    return getUrl(doc.uid, locale);
  }

  return getUrl(`/${doc.type}/${doc.uid}`, locale);
};

const getBrowserLocale = () => {
  if (typeof window !== 'object') {
    return defaultLocale;
  }

  return (
    (navigator.languages || [])
      .map((longCode) => {
        const parts = longCode.split('-');
        return parts[0];
      })
      .find((code) => {
        return locales.indexOf(code) !== -1;
      }) || 'en'
  );
};

module.exports = {
  getProductUrl,
  getCategoryUrl,
  getProductPremiumUrl,
  getArticleUrl,
  getEshopUrl,
  getLocale,
  getLegalLink,
  getCreditsLink,
  shops,
  locales,
  defaultLocale,
  getUrl,
  getStoriesUrl,
  resolvePrismicLink,
  getLocaleSettings,
  getConfiguratorUrl,
  getBrowserLocale,
  checkoutUrls,
  duplicatedLocales,
  getPrismicAlternates,
};

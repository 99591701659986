
const getImageResolution = (fullUrl, { width, height }) => {
  if (!fullUrl) {
    return undefined;
  }
  const dotPos = fullUrl.lastIndexOf('.');
  const baseUrl = fullUrl.substring(0, dotPos);
  const ext = fullUrl.substring(dotPos + 1);

  return `${baseUrl}_${width}x${height}.${ext}`;
};

const multiplyRes = (res, ratio) => {
  return Object.keys(res).reduce((acc, key) => {
    acc[key] = res[key] * ratio;
    return acc;
  }, {});
}

const pRatios = [2];
const breakpoints = [0.25, 0.5, 1.5, 2];

export const ShopifyFixed = (url, res, infos = {}) => {
  const result = { ...infos };
  const size = { ...res };
  if (!size.width && !size.height) {
    throw Error('ShopifyFixed no width or height specified');
  }

  if (!size.width) {
    size.width = size.height * infos.width / infos.height;
  }

  if (!size.height) {
    size.height = size.width * infos.height / infos.width;
  }

  result.src = getImageResolution(url, size);
  result.srcSet = pRatios
    .map(pr => `${getImageResolution(url, multiplyRes(size, pr))} ${pr}x`)
    .join(",\n");
  result.width = size.width;
  result.height = size.height;

  return result;
};

export const ShopifyFluid = (url, res, infos = { aspectRatio: 1 }) => {
  const result = { ...infos };
  const size = { };
  if (!res.maxWidth && !res.maxHeight) {
    throw Error('ShopifyFluid no maxWidth or maxHeight specified');
  }

  if (res.maxWidth) {
    size.width = res.maxWidth;
    size.height = Math.round(size.width / infos.aspectRatio);
  } else if (res.maxHeight) {
    size.height = res.maxHeight;
    size.width = Math.round(size.height * infos.aspectRatio);
  }

  result.src = getImageResolution(url, size);
  result.srcSet = breakpoints
    .map(pr => `${getImageResolution(url, multiplyRes(size, pr))} ${size.width * pr}w`)
    .join(",\n");
  
  if (!result.sizes) {
    result.sizes = `(max-width: ${size.width}px) 100vw, ${size.width}px`;
  }
  return result;
}
import React from 'react';
import styled from 'styled-components';

import { Icon } from '../components/icons';
import GlobeIcon from '../icons/DarkGlobe.svg';
import { useLocale } from '../services/i18n/useLocale';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;

  .globe {
  }

  span {
    font-weight: bold;
    padding: 5px 10px 5px 0;
  }

  a {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
  }
`;

const LocaleSelector = ({ alternateLocales = [] }) => {
  const intl = useLocale();

  return (
    <Wrapper>
      <Icon
        src={GlobeIcon}
        width={22}
        height={20}
        className="account-icon globe"
      />
      <span>{intl.locale}</span>
      {alternateLocales.map((alt) => (
        <a key={alt.locale} href={alt.url}>
          {alt.locale}
        </a>
      ))}
    </Wrapper>
  );
};

export default LocaleSelector;

import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useLocale } from '../../services/i18n/useLocale';
import { getLocaleSettings } from '../../utils';
import { useWidget } from '../widgets';

import { Wrapper } from './NewsletterForm.styles';

import trads from './trads';

const submit = async (email, locale = 'fr', addSamouraiTag = false) => {
  const mailchimpId = getLocaleSettings(locale).mailchimpId;
  const mailchimpUrl = `https://ipone.us6.list-manage.com/subscribe/post-json?u=97a03d45e032440cea22ba355&amp;id=${mailchimpId}`;

  const { default: jsonp } = await import(
    /* webpackChunkName: "jsonp" */ 'jsonp'
  );

  await jsonp(
    `${mailchimpUrl}&EMAIL=${email}&NEWSLETTER=Oui&LOCALE=${locale}${addSamouraiTag ? '&BOMBERS=Oui' : ''
    }`,
    { param: 'c' }
  );
};

const NewsletterForm = ({ submitIcon, addSamouraiTag = false }) => {
  const intl = useLocale();
  const ref = useRef();
  const [added, setAdded] = useState(false);
  const infos = useWidget('newsletter');

  React.useEffect(() => {
    if (!added) {
      return;
    }
    const timeout = setTimeout(() => setAdded(false), 5000);

    return () => clearTimeout(timeout);
  }, [added]);

  return (
    <Wrapper
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await submit(ref.current.value, intl.locale, addSamouraiTag);
        setAdded(true);
        ref.current.value = '';
        return false;
      }}
    >
      <input
        ref={ref}
        type="email"
        placeholder={
          added
            ? intl.formatMessage(trads.newsletterThankYou)
            : infos.placeholder
        }
        name="EMAIL"
        aria-label={infos.placeholder}
        className={added ? 'added' : ''}
      />
      <button
        type="submit"
        className={classNames('clear simple-link block', {
          'simple-link': !submitIcon,
        })}
      >
        {submitIcon ? submitIcon : infos.submitText}
      </button>
    </Wrapper>
  );
};

export default NewsletterForm;

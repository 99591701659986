import React from 'react';

import { Imgix } from '../../utils';

import {
  Wrapper,
} from './Partners.styles';

const Partners = ({
  title,
  partners = []
}) => {
  return (
    <Wrapper>
      <div className="container">
        {title ? <h2 className="redtitle">{title}</h2> : null}
        <ul>
          {partners.map((partner, idx) => {
            let currentItem = (
              <Imgix
                {...partner.logo}
                height={70}
                loading="lazy"
              />
            );

            if (partner.link?.url) {
              currentItem = (
                <a
                  href={partner.link.url || undefined}
                  target={partner.link.target}
                  rel="noopener"
                >
                  {currentItem}
                </a>
              );
            }

            return (
              <li
                key={idx}
              >
                {currentItem}
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
};

export default Partners;

import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import MagicLink from '../components/link';

import { Wrapper, Group } from './Submenu.styles';

// const chunk = (array = [], size = 3) => {
//   const chunked_arr = [];
//   let index = 0;
//   while (index < array.length) {
//     chunked_arr.push(array.slice(index, size + index));
//     index += size;
//   }
//   return chunked_arr;
// }

const getCols = (array = [], maxItems = 11, maxCats = 3) => {
  const chunked_arr = [];
  const sectionsLength = array.length;
  let index = 0;
  let nbItems = 0;
  let nbCat = 0;

  array.forEach((item) => {
    const size = item.items.length + 1;
    nbItems += size;
    nbCat++;

    if (nbItems > maxItems || nbCat > maxCats || sectionsLength <= 3) {
      index++;
      nbItems = size;
      nbCat = 1;
    }

    if (!chunked_arr[index]) {
      chunked_arr[index] = [];
    }

    chunked_arr[index].push(item);
  });

  return chunked_arr;
};

const Items = ({ items, onHide, header, isMobile = false }) => {
  const cols = getCols(items);
  // const cols = chunk(item.items, header && !isMobile ? 2 : 20);
  return cols.map((col, itemIdx) => {
    return (
      <Group
        key={itemIdx}
        className={cn({
          header,
        })}
      >
        {col.map((item, subidx) => (
          <div key={subidx} className="section">
            {item.title && (
              <MagicLink
                {...item.link}
                className="heading anim-item anim-item-1"
                onClick={onHide ? onHide : undefined}
                hideLink={!!item.seo_hide}
              >
                {item.title}
              </MagicLink>
            )}
            <div className="subitems">
              <ul>
                {item.items.map((subitem, idx) => (
                  <li key={idx} className={`anim-item anim-item-${idx + 2}`}>
                    <MagicLink
                      {...subitem.link}
                      onClick={onHide ? onHide : undefined}
                      hideLink={!!subitem.hideLink}
                    >
                      {subitem.title}
                    </MagicLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Group>
    );
  });
};

const Submenu = ({
  items,
  show,
  onHide,
  header = false,
  className,
  isMobile = false,
}) => {
  const [animate, setAnimated] = useState(false);

  // Wait for first draw to animate
  useEffect(() => {
    setAnimated(show);
  }, [show, setAnimated]);

  return (
    <Wrapper
      className={cn(className, {
        show,
        animate,
        header,
      })}
    >
      {header ? (
        <div className="container">
          <Items
            items={items}
            onHide={onHide}
            header={header}
            isMobile={isMobile}
          />
        </div>
      ) : (
        <Items items={items} header={header} isMobile={isMobile} />
      )}
    </Wrapper>
  );
};

export default Submenu;

import React, { useState } from 'react';
import { usePrevious, useEffectOnce } from 'react-use';
import cs from 'classnames';

import useShopify from '../../services/shopify';

import { Icon } from '../icons';
import CartIcon from '../../icons/Cart.svg';
import { isClient } from '../../utils';

import { Wrapper, CartCounter } from './CartMenu.styles';
import trads from './trads';
import { useLocale } from '../../services/i18n/useLocale';

//  webpackPrefetch: true
const CartPanel = React.lazy(() =>
  import(/* webpackChunkName: "cart" */ './CartPanel')
);

const CartMenu = ({ dark = false, onOpen, options }) => {
  const intl = useLocale();
  const [panel, setOpenPanel] = useState({
    load: false,
    open: false,
  });

  const closePanel = React.useCallback(() => {
    setOpenPanel((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  const openPanel = React.useCallback(() => {
    setOpenPanel({
      load: true,
      open: true,
    });
    onOpen && onOpen();
  }, [onOpen]);

  useEffectOnce(() => {
    if (!isClient || window.location.hash.indexOf('cart') === -1) {
      return;
    }
    openPanel();
  });

  const { checkout, adding, loading } = useShopify();
  const items = checkout ? checkout.lineItems.map((i) => i) : [];
  const quantity = items.reduce((acc, item) => acc + item.quantity, 0);
  const hasItems = !!quantity;

  const prevAdding = usePrevious(panel.open && !!adding);
  const showAdded = !adding && adding !== prevAdding;

  // if adding product,
  if (adding && !panel.open) {
    console.log('[Adding product]', adding, panel);
    openPanel();
  }
  // console.log('Render menu', panel, quantity);

  return (
    <Wrapper
      className={cs({
        dark,
      })}
    >
      <button
        className="clear menu-item-link"
        onClick={openPanel}
        aria-label="Panier"
      >
        <span>{intl.formatMessage(trads.header)}</span>
        <Icon src={CartIcon} width={20} height={20} />
        {hasItems ? <CartCounter>{quantity}</CartCounter> : null}
      </button>
      {panel.load ? (
        <React.Suspense fallback={<></>}>
          <CartPanel
            open={panel.open}
            onClose={closePanel}
            showAdded={showAdded}
            loading={loading}
            options={options}
          />
        </React.Suspense>
      ) : null}
    </Wrapper>
  );
};

export default CartMenu;

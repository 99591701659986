import React, { useCallback, useContext } from 'react';
import LocaleContext from './ctx';
import { formatMessage as innerFormatMessage } from './format';

export const useLocale = () => {
  return useContext(LocaleContext);
};

export const LocaleProvider = ({ locale, translations, children }) => {
  const formatMessage = useCallback(
    (key, params = {}) => {
      return innerFormatMessage(translations, key, params);
    },
    [translations]
  );
  return (
    <LocaleContext.Provider value={{ locale, formatMessage, translations }}>
      {children}
    </LocaleContext.Provider>
  );
};

import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #f3f3f3;

  .container {
    display: flex;
    flex-direction: column-reverse;
  }

  .text {
    flex: 1;
    padding: 50px 30px;
    text-align: left;

    p {
      color: rgb(17, 17, 17);
    }
  }

  .cover {
    background-color: #f3f3f3;
    flex: 1.5;
  }

  @media ${device.tabletDown} {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  @media ${device.tablet} {
    .container {
      display: flex;
      flex-direction: row;
    }
    .text {
      flex: 1;
      padding: 40px 30px;
      text-align: left;
    }

    .cover {
      flex: 1.1;

      .gatsby-image-wrapper {
        height: 100%;
      }
    }
  }

  @media ${device.laptop} {
    .cover {
      flex: 1.3;
    }
  }
`;

export const Title = styled.div`
  p {
    display: block;
    margin: 0;
    padding: 0;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 50px;
    text-transform: uppercase;
    line-height: 1.1em;

    strong {
      font-size: 30px;
      color: #e1251b;
      font-weight: 900;
    }
  }

  @media ${device.laptop} {
    p {
      font-size: 40px;

      strong {
        font-size: 50px;
      }
    }
  }
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 1.4em;

  p {
    margin: 25px 0;
  }

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.laptop} {
    font-size: 18px;

    p {
    }
  }
`;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  header: 'header.menu.cart',
  panelTitle: 'cart.panel.title',
  itemAdded: 'cart.panel.added',
  itemsCount: 'cart.panel.itemsCount',
  itemsCountPlural: 'cart.panel.itemsCountPlural',
  total: 'cart.panel.total',
  orderNow: 'cart.panel.orderNow',
  secure: 'cart.panel.secure',
  empty: 'cart.panel.empty',
  continue: 'cart.panel.continue',
  newsletterPromo: 'cart.newsletter.promo',
  addToCart: 'ecom.add-to-cart',
};

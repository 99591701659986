import React, { useContext } from 'react';

const ctx = React.createContext();

export { default as Partners } from './Partners';

export const useWidget = (name) => {
  const value = useContext(ctx);

  if (!value || !value[name]) {
    throw new Error(`[Widgets] Mising "${name}" widget data. Please check your page query`);
  }
  return value[name];
}

export default ctx;

import styled from 'styled-components';
import {
  device
} from '../../utils';

export const Wrapper = styled.section `
  position: relative;
  margin: 80px 0;
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: 20px;
    color: #000;
    text-transform: none;
    line-height: 1.2em;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    @media ${device.laptop} {
      margin-bottom: 30px;
    }
  }

  ul {
    display: block;
    margin: 20px auto 0 auto;
    padding: 0;
    max-width: 600px;

    li {
      display: inline-block;
      margin: 0 10px;

      a, div {
        display: block;
        max-width: 100%;
      }
    }

    li {
      max-width: calc(25% - 30px);
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        max-width: calc(50% - 30px);
      }
      &:nth-child(4) {
        max-width: calc(20% - 30px);
      }
      &:nth-child(5) {
        max-width: calc(30% - 30px);
      }
    }

    .gatsby-image-wrapper {
      img {
        object-fit: contain!important;
      }
    }
  }

  @media ${device.tablet} {
    ul {
      li {
        margin: 10px 20px;
        max-width: calc(25% - 50px);
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          max-width: calc(50% - 50px);
        }
        &:nth-child(4) {
          max-width: calc(20% - 50px);
        }
        &:nth-child(5) {
          max-width: calc(30% - 50px);
        }
      }
    }
  }

  @media ${device.laptop} {
    margin: 60px 0;

    ul {
      li {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          max-width: calc(25% - 50px);
        }
        &:nth-child(4) {
          max-width: calc(20% - 50px);
        }
        &:nth-child(5) {
          max-width: calc(30% - 50px);
        }
      }
    }
  }

  @media ${device.laptopM} {
    ul {
      li:nth-child(n) {
        max-width: 100%;
      }
    }
  }

  .com {
    padding-top: 0px;
    padding-bottom: 75px;

    @media ${device.tabletDown} {
      padding-bottom: 30px;
    
      .gatsby-image-wrapper {
        & > div {
          padding-bottom: 100%!important;
        }
      }
    }
  }
`;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import 'normalize.css';

import GlobalStyles from './globalStyles';

import Header from './header';
import Footer from './footer';
import Newsletter from '../components/common/Newsletter';
import CookieBanner from '../components/common/CookieBanner';
import Widgets from '../components/widgets';

import { ShopifyProvider } from '../services/shopify';

import { Wrapper, Main } from './index.styles';
import { defaultLocale, getLocaleSettings, locales } from '../utils';
import { LocaleProvider } from '../services/i18n/useLocale';
// import usePreviewData from '../utils/usePreviewData';

export const SiteInfos = React.createContext({
  title: '',
  description: '',
  siteUrl: '',
});

const preconnects = ['https://cdn.shopify.com', 'https://images.prismic.io'];

const Layout = ({ children, ...props }) => {
  const { pageContext = {}, localeData: trads, location } = props;
  const { pathname } = location;
  const { locale = 'fr', alternateLocales = [] } = pageContext;
  console.log('page context', pageContext);
  const {
    headerPosition = 'regular',
    mobileHeaderPosition,
    headerShadow = true,
  } = pageContext;

  /**
   * SEO alternates
   */
  const seoAlternates = [
    // current locale
    {
      locale,
      url: pathname,
    },
    ...alternateLocales.filter((alt) => locales.indexOf(alt.locale) !== -1),
  ];
  const xDefaultUrl = seoAlternates.find((alt) => alt.locale === 'en');
  if (xDefaultUrl) {
    seoAlternates.push({
      locale: 'x-default',
      url: xDefaultUrl.url,
    });
  }

  // const data = usePreviewData({
  //   ...rawData,
  // });

  /**
   * Auto redirect locale home
   */
  /*
  useEffect(() => {
    const recommendedLocale = getBrowserLocale();

    if (pathname !== '/' || recommendedLocale === locale) {
      return;
    }

    const goodPage = alternateLocales.find(p => p.locale === recommendedLocale);

    if (!goodPage) {
      return;
    }
    navigate(goodPage.url, { replace: true });
  }, [pathname, locale, alternateLocales]);
  */

  const baseUrl = trads?.site?.siteMetadata?.siteUrl;

  /**
   * set scrollbar width variable
   */
  // Avoid relayout
  // Might introduce bugs in product tables
  // useEffect(() => {
  //   if (typeof window === 'undefined') {
  //     return;
  //   }
  //   const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  //   document.documentElement.style.setProperty(
  //     '--scrollbar-width',
  //     `${scrollbarWidth}px`
  //   );
  // }, []);

  // console.log('***************', headerPosition, pageContext);

  let canonical = `${baseUrl}${pathname}`;

  // prevent duplicate homepage (/ vs /fr)
  if (pathname === '/' && defaultLocale === locale) {
    canonical = `${baseUrl}/${locale}`;
  }

  const shopSettings = getLocaleSettings(locale)?.shop;

  return (
    <LocaleProvider locale={locale} translations={trads.translations}>
      <Widgets.Provider value={trads.widgets}>
        <SiteInfos.Provider value={trads?.site?.siteMetadata}>
          <Helmet
            htmlAttributes={{
              lang: locale,
            }}
          >
            <link rel="canonical" href={canonical} />
            <meta property="og:url" content={`${baseUrl}${pathname}`} />
            {seoAlternates.map((alt) => {
              const localeSettings = getLocaleSettings(alt.locale, true);
              const localeCode = localeSettings?.fullLocale || alt.locale;
              return (
                <link
                  key={localeCode}
                  rel="alternate"
                  hrefLang={localeCode}
                  href={`${baseUrl}${alt.url}`}
                />
              );
            })}
            {preconnects.map((domain) => (
              <link key={domain} rel="preconnect" href={domain} />
            ))}
          </Helmet>
          <ShopifyProvider
            domain={shopSettings?.domain}
            token={shopSettings?.token}
            locale={locale}
          >
            <Wrapper
              className={classnames({
                regular: headerPosition === 'regular',
                sticky: headerPosition === 'sticky',
                'mobile-sticky': mobileHeaderPosition === 'sticky',
              })}
            >
              <GlobalStyles />
              <Header
                position={headerPosition}
                displayShadow={headerShadow}
                alternateLocales={alternateLocales}
                menu={trads.headerMenu}
                shopOptions={trads.shopOptions}
              />
              <Main>
                {children}
                <Newsletter />
              </Main>
              <CookieBanner />
              <Footer menu={trads.footerMenu?.sections || []} />
            </Wrapper>
          </ShopifyProvider>
        </SiteInfos.Provider>
      </Widgets.Provider>
    </LocaleProvider>
  );
};

export const query = graphql`
  # fragment Menu on PrismicMenu {
  #   data {
  #     body {
  #       ... on PrismicMenuBodyLien {
  #         primary {
  #           link {
  #             url
  #             type
  #             lang
  #             target
  #           }
  #           name
  #           header
  #           footer
  #         }
  #         items {
  #           name
  #           link {
  #             lang
  #             target
  #             type
  #             url
  #           }
  #           is_category
  #           subtitle
  #         }
  #       }
  #     }
  #   }
  # }
  fragment ProductItem on ShopifyProduct {
    availableForSale
    description
    handle
    id
    shopifyId
    productType
    title
    defaultVariant {
      shopifyId
      title
      sku
      price
      compareAtPrice
      viscosity
    }
    skus
    tags
    thumbnail {
      originalSrc
      id
      # localThumb {
      #   childImageSharp {
      #     fixed(height: 100) {
      #       tracedSVG,
      #       width,
      #       height
      #     }
      #   }
      # }
    }
  }

  fragment CategoryItem on ShopifyCollection {
    id
    title
    handle
    # image {
    #   src
    #   localFile {
    #     childImageSharp {
    #       fixed(quality: 80, width: 310, height: 240, toFormat: JPG, cropFocus: CENTER) {
    #         ...GatsbyImageSharpFixed_withWebp
    #       }
    #     }
    #   }
    # }
  }

  # fragment WidgetConfigurator on PrismicWidgets {
  #   configurator: data {
  #     linkName: configurator_link_name
  #     text: configurator_text
  #     title: configurator_title
  #     brands: configurator_brands {
  #       logo {
  #         url
  #         dimensions {
  #           height
  #           width
  #         }
  #         alt
  #       }
  #     }
  #   }
  # }

  # fragment WidgetPartners on PrismicWidgets {
  #   partners: data {
  #     title: partners_title {
  #       text
  #     }
  #     partners: partners_items {
  #       link {
  #         target
  #         url
  #       }
  #       logo {
  #         url
  #         alt
  #         url
  #         dimensions {
  #           width
  #           height
  #         }
  #       }
  #     }
  #   }
  # }
`;

export default Layout;

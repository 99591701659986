const regroup = (obj) => {
  return Object.keys(obj).reduce((res, field) => {
    const parts = field.split('_');
    const prefix = parts.shift();

    const newField = parts
      .map((p, idx) => (idx === 0 ? p : p.charAt(0).toUpperCase() + p.slice(1)))
      .join('');

    if (!newField) {
      res[field] = obj[field];
      return res;
    }

    let node = res[prefix];
    if (!node) {
      res[prefix] = node = {};
    }

    node[newField] = obj[field];

    return res;
  }, {});
};

module.exports = {
  regroup
};
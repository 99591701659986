import styled from 'styled-components';
import { device } from '../utils';

export const Wrapper = styled.div`
  &.header {
    padding: 0;
    transition: opacity 300ms ease-out;
    backface-visibility: hidden;
    display: none;
    opacity: 0;

    &.show {
      display: block;
    }

    .anim-item {
      opacity: 0;
      /* transform: translateY(-10px); */
      /* transition: transform 300ms ease-out, opacity 300ms ease-out; */
      /* backface-visibility: hidden; */
    }

    &.animate {
      opacity: 1;
      
      .anim-item {
        animation: menu-item-animation 300ms forwards;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &.anim-item-1 {
          animation-delay: 100ms;
        }
        &.anim-item-2 {
          animation-delay: 200ms;
        }
        &.anim-item-3 {
          animation-delay: 300ms;
        }
        &.anim-item-4 {
          animation-delay: 400ms;
        }
        &.anim-item-5 {
          animation-delay: 500ms;
        }
        &.anim-item-6 {
          animation-delay: 600ms;
        }
        &.anim-item-7 {
          animation-delay: 700ms;
        }
        &.anim-item-8 {
          animation-delay: 800ms;
        }
        &.anim-item-9 {
          animation-delay: 900ms;
        }
      }
    }

    @keyframes menu-item-animation {
      0% {
        opacity: 0;
        transform: translate3d(0, -15px, 0);
      }
      to {
        opacity: 1;
        transform: translate3d(0, 0px, 0);
      }
    }

    & > .container {
      display: flex;
      flex-direction: column;
      padding: 0;
      /* align-items: flex-start; */
    }

    @media ${device.laptop} {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 11000;
      background-color: rgba(var(--dm-background-color), 0.98);
      box-shadow: inset 0px 3px 6px 0 rgba(0,0,0,0.15);
      padding: 30px 0;

      & > .container {
        flex-direction: row;
        padding: 0 20px;
      }
    }
  }
  
  &.white {
    &, a {
      color: #FFF;
    }
  }
  
`;

export const Group = styled.div`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;

  .heading {
    font-family: 'Montserrat';
    /* display: none; */
    flex: 0;
    font-weight: bold;
    margin-bottom: 5px;
  }
  a.heading {
    position: relative;
    transition: color 300ms ease-out;

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: -20px;
      display: inline-block;
      width: 10px;
      height: 12px;
      background: #E1251B;
      transform: skew(-20deg) scaleX(0);
      transition: transform 300ms ease-out;
    }

    &:hover {
      color: #c80f05;

      &:before {
        transform: skew(-20deg) scaleX(1);
      }
    }
  }

  .subitems {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .section {
    margin-bottom: 20px;
  }

  ul {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding-left: 0;
    margin: 0;
    list-style: none;

    &:last-child {
      margin-right: 0;
    }

    & > li {
      margin: 5px 0;

      & > a, & > span {
        position: relative;
        display: block;
        letter-spacing: 0.1px;
        font-size: 12px;
      }

      & > a, & > span[role=button] {
        transition: color 300ms ease-out;
        cursor: pointer;

        span {
          display: block;
          font-size: 12px;
          letter-spacing: 0.1px;
          color: #cccccc;
          font-weight: normal;
        }

        /* &.uppercase {
          text-transform: uppercase;
        } */

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -20px;
          display: inline-block;
          width: 8px;
          height: 12px;
          background: #E1251B;
          transform: skew(-20deg) scaleX(0);
          transition: transform 300ms ease-out;
        }

        &:hover {
          color: #c80f05;

          &:before {
            transform: skew(-20deg) scaleX(1);
          }
        }
      }
    }
  }

  &.header {
    .heading {
      display: block;
    }

    .col {
      & > li {
        & > a {
          span {
            color: #404143;
          }
        }
      }
    }
  }

  @media ${device.tablet} {
    margin-top: 0;
    margin-left: 0;
  }

  @media ${device.laptop} {
    &.header {
      padding-left: 30px;
      margin-right: 20px;

      &:first-child {
        padding-left: 210px;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .col {
      margin-right: 100px;

      & > li {
        & > a {
          &:before {
            display: block;
          }
        }
      }
    }

    .subitems {
      flex-direction: row;
    }
  }
`;
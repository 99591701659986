import React from 'react';
import Partners from '../common/Partners';

import { useWidget } from '../widgets';

const PartnersWidget = () => {
  const { title, partners = []} = useWidget('partners');

  return (
    <Partners
      title={title?.text}
      partners={partners}
    />
  );
}

export default PartnersWidget;

import styled from 'styled-components';

export const CartCounter = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: -5px;
  right: 0;
  min-width: 18px;
  background: #FFF;

  padding: 5px 5px 3px 5px;
  text-align: center;
  
  color: #000;
  border-radius: 50%;
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
`;


export const Wrapper = styled.div`
  .menu-item-link {
    position: relative;
  }
  svg {
    margin-left: 5px;
    margin-top: -3px;
  }
  a {
    cursor: pointer;
  }

  .sliding-panel-container {
    .panel-content {
      background: #FFF;
    }
  }

  &.dark {
    svg * {
      stroke: #000;
    }

    ${CartCounter} {
      background: #000;
      color: #FFF;
    }
  }
`;


import styled from 'styled-components';

import { device } from '../utils';

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;

  background-color: rgba(var(--dm-background-color), 0.98);
  transition: background-color 0.6s;
  backdrop-filter: saturate(110%) blur(2px);
  box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.15);

  @media ${device.laptop} {
    &,
    a {
      color: var(--dm-color);
    }
  }

  &.hide-shadow {
    box-shadow: none;
  }

  &:hover,
  &.is-floating {
    background-color: rgba(var(--dm-background-color), 0.98);
  }

  &:hover {
    z-index: 1200;
  }

  &.debug {
    background-color: rgba(255, 255, 255, 0.2);
    .container {
      background-color: rgba(200, 100, 0, 0.4);
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: calc(50vw - (1440px / 2));
    background: #000;
  }

  &.is-menu-open {
    background-color: #000 !important;

    .menu-mobile {
      height: -webkit-fill-available;
      height: calc(100vh - var(--header-height));
    }
  }

  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-right: 20px solid #000;
    padding-right: 0;

    @media (min-width: 1025px) {
      border-right-width: 0px;
    }
  }

  .logo {
    padding: 15px 0;
    position: relative;

    span {
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
    }

    a {
      display: block;
      line-height: 0;
    }
  }

  .menu {
    flex: 1;
    display: none;
    justify-content: flex-start;
    margin: 0;
    align-self: stretch;
    list-style: none;

    & > li {
      margin: 0;
      display: flex;
      align-items: center;

      & > a {
        display: flex;
        font-style: normal;
        font-size: 14px;
        padding: 0 10px;
        align-items: center;
        height: 100%;
      }
    }
    @media (min-width: 1120px) {
      display: flex;
    }

    @media (min-width: 1190px) {
      & > li {
        & > a {
          font-size: 15px;
          padding: 0px 15px;
        }
      }
    }
  }

  .entry-link {
    font-family: 'Montserrat';
    position: relative;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      content: '';
      position: absolute;
      left: 10px;
      right: 10px;
      z-index: 9;
      height: 2px;
      bottom: 0;
      background-color: #000;
      transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      transform: scaleX(0);
      transform-origin: left;
    }

    &:hover,
    &.hover {
      &:after {
        transform: scaleX(1) scaleY(1);
      }
    }
  }

  .right {
    display: flex;
    height: auto;
    background-color: #000;
    color: #fff;
    align-self: stretch;
    align-items: center;
    padding: 0 0px 0 20px;
    margin: 0;
    list-style: none;
    margin-left: auto;

    @media ${device.tabletDown} {
      padding-left: 5px;

      .search-icon {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    li {
      margin: 0 5px;
    }

    .menu-item-link {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      padding: 5px 7px;

      &.locale {
        padding-left: 0;
        padding-right: 0;
      }

      span {
        &:before {
          display: none;
        }
      }

      a {
        color: #fff;
      }

      svg {
        margin-right: 5px;
      }
    }

    .burger {
      margin-top: -2px;
    }
  }

  svg.account-icon {
    fill: none;
    margin-left: 7px;

    &.white {
      * {
        fill: #fff;
      }
    }
  }

  .menu-item-desktop {
    &:not(.show-mobile) {
      display: none !important;

      @media ${device.laptop} {
        display: flex !important;
      }
    }

    @media ${device.tabletDown} {
      &.show-mobile {
        padding-right: 5px;
      }
    }

    @media (max-width: 1390px) {
      &.menu-item-link,
      .menu-item-link {
        span {
          display: none;
        }
      }
    }
  }

  .menu-item-burger {
    @media (min-width: 1120px) {
      display: none !important;
    }
  }
`;

import styled from 'styled-components';
import { device } from '../utils';

export const Wrapper = styled.footer`
  position: relative;
  background: #000;
  padding: 30px 20px;

  color: #FFF;

  .container {
    position: relative;
    z-index: 1;
  }

  .top {
    margin-bottom: 40px;

    .menu {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      > li {
        list-style: none;
        /* width: 50%; */
        padding-right: 20px;
        box-sizing: border-box;
        margin-bottom: 30px;

        & > .menu-link {
          font-family: 'Montserrat';
        }

        a, .menu-link, span {
          /* font-weight: */
          font-size: 15px;
          line-height: 1.3em;
        }

        .col {
          & > li {
            margin: 7px 0;
          }
        }
      }

      @media ${device.tabletDown} {
        .subitems {
          & > div {
            margin-left: 0;
          }
        }
      }

      @media ${device.tablet} {
        > li {
          /* width: 33%; */
        }
      } 


      @media ${device.laptop} {
        margin: 0 30px 0 0;

        > li {
          flex: 1;
          margin-right: 40px;
          padding-right: 0;
          margin-bottom: 0;
        }
      }
    }

    .subitems {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding-left: 0;
      list-style: none;

      li {
        margin: 7px 0px;

        a, span {
          position: relative;
          display: block;
          font-size: 12px;
          color: #FFF;
          line-height: 1.3em;
          font-weight: 500;
          letter-spacing: 0.1px;
          transition: color 300ms ease-out 0s;
        }

        a:before {
          content: "";
          position: absolute;
          top: 0px;
          left: -25px;
          width: 12px;
          height: 16px;
          background: rgb(225, 37, 27);
          transform: skew(-20deg) scaleX(0);
          transition: transform 300ms ease-out 0s;
        }

        a:hover {
          color: rgb(200, 15, 5);

          &:before {
            display: block;
            transform: skew(-20deg) scaleX(1);
          }
        }
      }
    }

    .social {
      text-align: center;

      a {
        padding: 5px 10px;
        display: inline-block;
        display: inline-flex;
        min-width: 48px;
        min-height: 48px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
      }
    }

    @media ${device.laptop} {
      display: flex;
      margin-bottom: 40px;

      .social {
        margin-left: auto;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  
    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .legal {
        margin-top: 20px;
        text-align: center;
        font-size: 12px;
        line-height: 1.6em;
      
        &, a, span {
          color: #fafafa;
          text-decoration: none;
        }
      }
    }

    @media ${device.laptop} {
      display: flex;
      align-items: flex-end;
      flex-direction: row;

      .logo {
        flex-direction: row;
        align-items: flex-end;
        margin-top: 0;

        .legal {
          margin-top: 0;
          text-align: left;
          margin-left: 30px;
        }
      }
    }
  }
  .background {
    position: absolute;
    display: none;

    .gatsby-image-wrapper {
      width: 100%;
      max-height: 100%;
      /* opacity: 0.1; */
    }

    @media ${device.tablet} {
      right: 0;
      bottom: 0;
      top: 30%;
      left: 45%;
      display: flex;
      align-items: flex-end;
    }
  }
`;